import Index from 'views/Index.js';

import Profile from 'views/examples/Profile.js';
import EditProfile from './views/examples/EditProfile';

import Register from 'views/examples/Register.js';
import Login from 'views/examples/Login.js';
import ConfirmEmail from './views/examples/ConfirmEmail';
import ResetPassword from './views/examples/ResetPassword';
import ConfirmPassword from './views/examples/ConfirmPassword';
import ResetPasswordSuccess from './views/examples/ResetPasswordSuccess';

import Hashtag from 'views/HashTag';
import CreateEvent from 'views/CreateEvent';
import EditEvent from 'views/EditEvent';
import AllEvents from 'views/AllEvents';
import Help from 'views/Help';
import Plans from 'views/Plans';
import Settings from 'views/Settings';
import ManageUsers from 'views/ManageUsers';
import ManageTicketSales from 'views/ManageTicketSales';
import Notifications from 'views/Notifications';
import Chat from 'views/Chat';

var routes = [
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin',
    admin: false,
  },
  {
    path: '/create-event',
    name: 'Create Event',
    icon: 'ni ni-calendar-grid-58 text-primary',
    component: CreateEvent,
    layout: '/admin',
    admin: false,
  },
  {
    path: '/edit-event',
    name: 'Edit Event',
    icon: 'ni ni-calendar-grid-58 text-primary',
    component: EditEvent,
    layout: '/admin',
    admin: true,
  },
  {
    path: '/events',
    name: 'All Events',
    icon: 'ni ni-album-2 text-primary',
    component: AllEvents,
    layout: '/admin',
    admin: false,
  },
  {
    path: '/hashtag',
    name: 'Hashtags',
    icon: 'fas fa-hashtag text-primary',
    component: Hashtag,
    layout: '/admin',
    admin: false,
  },
  // {
  //   path: '/index',
  //   name: 'Payments',
  //   icon: 'ni ni-money-coins text-primary',
  //   component: Index,
  //   layout: '/admin',
  //   admin: false,
  // },
  {
    path: '/settings',
    name: 'Settings',
    icon: 'ni ni-settings text-primary',
    component: Settings,
    layout: '/admin',
    admin: false,
  },
  {
    path: '/help',
    name: 'Help',
    icon: 'ni ni-vector text-primary',
    component: Help,
    layout: '/admin',
    admin: false,
  },
  {
    path: '/plans',
    name: 'Premium',
    icon: 'ni ni-bag-17 text-primary',
    component: Plans,
    layout: '/admin',
    admin: false,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    layout: '/admin',
    admin: false,
    hide: true,
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    layout: '/admin',
    admin: false,
    hide: true,
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    icon: 'ni ni-single-02 text-primary',
    component: Profile,
    layout: '/admin',
    admin: false,
  },
  {
    path: '/edit-profile',
    name: 'Edit Profile',
    icon: 'ni ni-ruler-pencil text-info',
    component: EditProfile,
    layout: '/admin',
    admin: false,
    hide: true,
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    admin: false,
  },
  {
    path: '/register',
    name: 'Register',
    icon: 'ni ni-circle-08 text-pink',
    component: Register,
    layout: '/auth',
    admin: false,
  },
  {
    path: '/confirm-email/:id',
    name: 'Confirm Email',
    icon: 'ni ni-check-bold text-green',
    component: ConfirmEmail,
    layout: '/auth',
    admin: false,
  },
  {
    path: '/users',
    name: 'Manage Users',
    icon: 'fas fa-users text-pink',
    component: ManageUsers,
    layout: '/admin',
    admin: true,
  },
  {
    path: '/ticket-sales',
    name: 'Manage Ticket Sales',
    icon: 'fas fa-ticket-alt text-red',
    component: ManageTicketSales,
    layout: '/admin',
    admin: true,
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    icon: 'ni ni-folder-17 text-pink',
    component: ResetPassword,
    layout: '/auth',
    admin: false,
  },
  {
    path: '/confirm-password/:id',
    name: 'Confirm Password',
    icon: 'ni ni-folder-17 text-pink',
    component: ConfirmPassword,
    layout: '/auth',
    admin: false,
  },
  {
    path: '/reset-success',
    name: 'Password Reset Confirmed',
    icon: 'ni ni-folder-17 text-pink',
    component: ResetPasswordSuccess,
    layout: '/auth',
    admin: false,
  },
];
export default routes;
