import { Col, Container, Row } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthFooter from 'components/Footers/AuthFooter.js';
import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import React from 'react';
import logoWhite from 'assets/img/brand/leventix-hoch-white.svg';
import routes from 'routes.js';

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-default');
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default');
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className='main-content'>
          <AuthNavbar />
          <div className='header bg-gradient-app py-7 py-lg-8'>
            <Container>
              <div className='header-body text-center mb-7'>
                <Row className='justify-content-center'>
                  <Col lg='5' md='6'>
                    <img alt='logo' src={logoWhite} width='180' />
                  </Col>
                </Row>
              </div>
            </Container>
            <div className='separator separator-bottom separator-skew zindex-100'>
              <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' version='1.1' viewBox='0 0 2560 100' x='0' y='0'>
                <polygon className='fill-default' points='2560 0 2560 100 0 100' />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className='mt--8 pb-5'>
            <Row className='justify-content-center'>
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from='*' to='/auth/login' />
              </Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
