// reactstrap components
import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import React, { useState } from 'react';

import Toast from 'react-bootstrap/Toast';
import { forgotPassword } from '../../network/ApiAxios';
import leventixLogoPng from '../../assets/img/brand/leventix.png';
// i18n
import { useTranslation } from 'react-i18next';

const ResetPassword = (props) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('Email sent! Check it to reset your password.');

  const sendEmail = async () => {
    const response = await forgotPassword(email);
    const { data } = response;
    if (data.success) {
      console.log(data);
      setShowToast(true);
      setTimeout(() => {
        props.history.push('/');
      }, 3000);
    } else {
      setError(data.errors[0].msg);
    }
  };

  return (
    <>
      <div
        aria-live='polite'
        aria-atomic='true'
        style={{
          position: 'fixed',
          minHeight: '100px',
          width: '35%',
          right: 10,
          bottom: 100,
          zIndex: 50,
        }}
      >
        <Toast
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'white',
            padding: 10,
            borderRadius: 10,
          }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide={true}
        >
          <Toast.Header>
            <img style={{ width: '60px' }} src={leventixLogoPng} alt='...' />
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </div>
      <Col lg='5' md='7'>
        <Card className='bg-secondary shadow border-0'>
          <CardBody className='px-lg-5 py-lg-5'>
            <Form role='form'>
              <FormGroup className='mb-3'>
                <InputGroup className='input-group-alternative'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <i className='ni ni-email-83' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder={t('Email')} type='email' autoComplete='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                </InputGroup>
              </FormGroup>
              {error ? (
                <div className='text-muted font-italic'>
                  <small>
                    error: <span className='text-red font-weight-700'>{error}</span>
                  </small>
                </div>
              ) : null}
              <div className='text-center'>
                <Button className='my-4' color='primary' type='button' onClick={sendEmail}>
                  {t('Reset Password')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ResetPassword;
