import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Navbar, Nav, NavItem, NavLink, Container, Media } from 'reactstrap';
// i18n
import { useTranslation } from 'react-i18next';

import { logout } from '../../network/ApiAxios';
import './AdminNavbar.scss';
import UserContext from 'context/UserContext';
import config from '../../config';

const AdminNavbar = (props) => {
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);

  const logOut = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const response = await logout(token);
      const { data } = response;
      if (data.success) {
        localStorage.removeItem('token');
        setUser(null);
        props.history.push('/auth/login');
      }
    }
  };

  return (
    <>
      <Navbar className='navbar-top navbar-dark' expand='md' id='navbar-main'>
        <Container fluid>
          <span className='h4 mb-0 text-white text-uppercase d-none d-lg-inline-block'>{props.brandText}</span>
          <Nav className='align-items-center d-none d-md-flex' navbar>
            <NavItem>
              <NavLink className='nav-link-icon icon-shape ' to='/admin/notifications' tag={Link}>
                <i className='ni ni-notification-70' />
                {/* <span className='nav-link-inner--text'>Notification</span> */}
              </NavLink>
            </NavItem>
            {user.userType !== 'superAdmin' ? (
              <NavItem>
                <NavLink className='nav-link-icon icon-shape ' to='/admin/chat' tag={Link}>
                  <i className='ni ni-chat-round' />
                </NavLink>
              </NavItem>
            ) : null}

            <UncontrolledDropdown nav>
              <DropdownToggle className='pr-0' nav>
                <Media className='align-items-center'>
                  <span className='avatar avatar-sm rounded-circle bg-white'>
                    {user?.image ? (
                      <img alt='profile' src={user.image} />
                    ) : (
                      <i className='fas fa-user-tie text-primary' style={{ fontSize: '18px' }} />
                    )}
                  </span>
                  <Media className='ml-2 d-none d-lg-block'>
                    <span className='mb-0 text-sm font-weight-bold'>{user.name}</span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-arrow' right>
                <DropdownItem href='#' onClick={logOut}>
                  <i className='ni ni-user-run' />
                  <span>{t('Logout')}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
