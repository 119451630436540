/*eslint-disable*/
import React from 'react';
// reactstrap components
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
// i18n
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className='footer'>
      <Row className='align-items-center justify-content-xl-between'>
        <Col xl='6'>
          <div className='copyright text-center text-xl-left text-muted'>
            © {new Date().getFullYear()}{' '}
            <a className='font-weight-bold ml-1' href='https://www.leventix.de/' target='_blank'>
              Leventix
            </a>
          </div>
        </Col>

        <Col xl='6'>
          <Nav className='nav-footer justify-content-center justify-content-xl-end'>
            <NavItem>
              <NavLink href='https://www.leventix.de/' target='_blank'>
                Leventix
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href='https://www.leventix.de/' target='_blank'>
                {t('About Us')}
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
