import React, { useState, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { userMyDetails } from './network/ApiAxios';

import AdminLayout from './layouts/Admin.js';
import AuthLayout from './layouts/Auth.js';
import ErrorLayout from './layouts/Error.js';
import AuthRoutes from './components/PrivateRoute/AuthRoutes';
import PaymentLayout from 'layouts/Payment.js';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import AlertContext from './context/AlertContext';
import UserContext from './context/UserContext';

const App = (props) => {
  const { i18n } = useTranslation();

  const [alertData, setAlertData] = useState({
    show: false,
    success: false,
    message: '',
  });
  const [user, setUser] = useState();

  // hide the alert banner after 5secs
  useEffect(() => {
    if (alertData.show) {
      setTimeout(() => {
        setAlertData({ ...alertData, show: false });
      }, 5000);
    }
  }, [alertData]);

  useEffect(() => {
    // remove old storage
    localStorage.removeItem('user');

    const token = localStorage.getItem('token');
    if (token) {
      (async () => {
        const { data } = await userMyDetails();
        if (data?.accountSettings?.locale) {
          i18n.changeLanguage(data.accountSettings.locale);
        }
        setUser(data);
      })();
    }
  }, []);

  // update the alert banner to show
  const handleAlertData = (data) => {
    setAlertData({ ...alertData, ...data, show: true });
  };

  return (
    <AlertContext.Provider value={{ alertData: alertData, updateAlertData: handleAlertData }}>
      <UserContext.Provider value={{ user: user, setUser: setUser }}>
        <BrowserRouter>
          <Switch>
            <Route path='/payment/:id' component={PaymentLayout} />
            <PrivateRoute path='/admin' component={AdminLayout} user={user} />
            <AuthRoutes path='/auth' component={AuthLayout} user={user} />
            <Route path='/error' component={ErrorLayout} />
            <Redirect from='/' to='/admin/index' />
          </Switch>
        </BrowserRouter>
      </UserContext.Provider>
    </AlertContext.Provider>
  );
};

export default App;
