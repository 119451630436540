import React, { useState, useContext, useEffect } from 'react';
// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col, Table } from 'reactstrap';
import moment from 'moment';
// core components
import Header from 'components/Headers/Header.js';
import AlertContext from 'context/AlertContext';
// api's
import { fetchNotifications } from '../network/ApiAxios';

const Notifications = () => {
  const { updateAlertData } = useContext(AlertContext);
  const [notifications, setNotifications] = useState([]);

  // fetchNotifications
  const getNotifications = async () => {
    try {
      const { data } = await fetchNotifications();
      setNotifications(data);
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <Header hideStats={true} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Card className='bg-secondary shadow'>
          <CardHeader className='bg-white border-0'>
            <Row className='align-items-center'>
              <Col xs='8'>
                <h3 className='mb-0'>Notifications</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table className='align-items-center table-flush' responsive>
              <tbody>
                {notifications?.map((not) => (
                  <tr key={not._id}>
                    <td>
                      <i className={`${not.type === 'Event' ? 'ni ni-album-2' : 'fas fa-ticket-alt'} text-pink mr-2`}></i>
                      {not.type === 'Event'
                        ? `Event "${not.title}" was created at ${moment(not.created).format('YYYY-MM-DD HH:mm:ss')}`
                        : `Tickets Sold worth "${not.amount} ${not.currency}" for Event "${not.eventId.title}" at
                              ${moment(not.created).format('YYYY-MM-DD HH:mm:ss')}`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Notifications;
