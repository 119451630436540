import React, { useState } from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from 'reactstrap';
// i18n
import { useTranslation } from 'react-i18next';

import { confirmReset } from '../../network/ApiAxios';
import { useParams } from 'react-router-dom';

const ConfirmPassword = (props) => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const confirm = async () => {
    if (password !== confirmPassword) {
      setError('Passwords have to match');
      return;
    }
    const response = await confirmReset(id, password);
    const { data } = response;
    if (data.success) {
      props.history.push('/auth/reset-success');
    } else {
      setError(data.msg);
    }
  };

  return (
    <>
      <Col lg='5' md='7'>
        <Card className='bg-secondary shadow border-0'>
          <CardBody className='px-lg-5 py-lg-5'>
            <Form role='form'>
              <FormGroup>
                <InputGroup className='input-group-alternative'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <i className='ni ni-lock-circle-open' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('Password')}
                    type='password'
                    autoComplete='new-password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className='input-group-alternative'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <i className='ni ni-lock-circle-open' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('Confirm Password')}
                    type='password'
                    autoComplete='new-password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              {error ? (
                <div className='text-muted font-italic'>
                  <small>
                    error: <span className='text-red font-weight-700'>{error}</span>
                  </small>
                </div>
              ) : null}
              <div className='text-center'>
                <Button className='my-4' color='primary' type='button' onClick={confirm}>
                  {t('Reset Password')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ConfirmPassword;
