import React, { useState, useEffect, useContext } from 'react';
// reactstrap components
import {
  Card,
  // CardHeader,
  CardBody,
  Container,
  Row,
  Button,
  FormGroup,
  Form,
  Input,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from 'reactstrap';
import _ from 'lodash';
import classnames from 'classnames';
// i18n
import { useTranslation } from 'react-i18next';
// core components
import Header from 'components/Headers/Header.js';
import AlertContext from 'context/AlertContext';
// api's
import { userMyDetails, edit, changePassword } from '../network/ApiAxios';

const SettingsPage = () => {
  const { t } = useTranslation();

  const { updateAlertData } = useContext(AlertContext);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user')));
  const [tabs, setTabs] = useState(1);
  const [formData, setFormData] = useState({});

  // handle form fields changes
  const handleChange = (name, value, index, subName) => {
    const newFormData = _.cloneDeep(formData);
    newFormData[name] = value;
    setFormData(newFormData);
  };

  // handlePaypalCreds
  const handlePaypalCreds = async () => {
    try {
      const newFormData = _.cloneDeep(formData);
      const newUserData = _.cloneDeep(userData);
      if (!newUserData.adminSettings) {
        newUserData.adminSettings = {};
      }
      if (!newUserData.adminSettings.paypal) {
        newUserData.adminSettings.paypal = {};
      }
      newUserData.adminSettings.paypal['clientId'] = newFormData.clientId;
      newUserData.adminSettings.paypal['clientSecret'] = newFormData.clientSecret;
      await edit(userData._id, newUserData);

      getUserInfo();
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // handleUpdatePassword
  const handleUpdatePassword = async () => {
    try {
      const newFormData = _.cloneDeep(formData);
      if (
        !newFormData.password ||
        !newFormData.confirmPassword ||
        newFormData.password !== newFormData.confirmPassword
      ) {
        updateAlertData({ success: false, message: 'Password is required and need to match with confirm!' });
      }
      await changePassword(newFormData.password);
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // fetch userInfo
  const getUserInfo = async () => {
    try {
      const { data } = await userMyDetails();
      setUserData(data);
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // initial load
  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <>
      <Header hideStats={true} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Card className='bg-secondary shadow'>
          {/* <CardHeader className='bg-white border-0'>
            <Row className='align-items-center'>
              <Col xs='8'>
                <h3 className='mb-0'>Settings</h3>
              </Col>
            </Row>
          </CardHeader> */}
          <CardBody>
            <div className='nav-wrapper'>
              <Nav className='nav-fill flex-column flex-md-row' id='tabs-icons-text' pills role='tablist'>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames('mb-sm-3 mb-md-0 font-weight-bold', { active: tabs === 1 })}
                    onClick={() => setTabs(1)}
                    role='tab'
                  >
                    <i className='fab fa-paypal mr-2' />
                    {t('PayPal Settings')}
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    aria-selected={tabs === 2}
                    className={classnames('mb-sm-3 mb-md-0 font-weight-bold', { active: tabs === 2 })}
                    onClick={() => setTabs(2)}
                    role='tab'
                  >
                    <i className='fas fa-envelope-open-text mr-2' />
                    Email Settings
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 3}
                    className={classnames('mb-sm-3 mb-md-0 font-weight-bold', { active: tabs === 3 })}
                    onClick={() => setTabs(3)}
                    role='tab'
                  >
                    <i className='fas fa-key mr-2' />
                    {t('Change Password')}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <Card className='shadow'>
              <CardBody>
                <TabContent activeTab={'tabs' + tabs}>
                  <TabPane tabId='tabs1'>
                    <Form>
                      <br />
                      <div className='pl-lg-4'>
                        <Row>
                          <Col lg='8'>
                            <FormGroup>
                              <label className='form-control-label' htmlFor='input-title'>
                                {t('PayPal Client ID')}
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-title'
                                placeholder={t('PayPal Client ID')}
                                value={userData?.adminSettings?.paypal?.clientId}
                                onChange={(e) => handleChange('clientId', e.target.value)}
                                required
                              />
                            </FormGroup>
                            <FormGroup>
                              <label className='form-control-label'>{t('PayPal Client Secret')}</label>
                              <Input
                                className='form-control-alternative'
                                placeholder={t('PayPal Client Secret')}
                                value={userData?.adminSettings?.paypal?.clientSecret}
                                onChange={(e) => handleChange('clientSecret', e.target.value)}
                                required
                              />
                            </FormGroup>
                            <Button
                              className='btn-icon btn-3'
                              color='success'
                              type='button'
                              onClick={handlePaypalCreds}
                            >
                              <i className='fas fa-save' />
                              <span className='btn-inner--text'>{t('Save')}</span>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <br />
                    </Form>
                  </TabPane>
                  {/* <TabPane tabId='tabs2'>
                    <Form>
                      <br />
                      <div className='pl-lg-4'>
                        <Row>
                          <Col lg='8'>
                            <FormGroup>
                              <label className='form-control-label' htmlFor='input-title'>
                                Mail Host
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-title'
                                placeholder='Mail Host'
                                value={''}
                                onChange={(e) => handleChange('clientId', e.target.value)}
                                required
                              />
                            </FormGroup>
                            <FormGroup>
                              <label className='form-control-label' htmlFor='input-number'>
                                Mail Port
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-number'
                                placeholder='Mail Port'
                                value={''}
                                onChange={(e) => handleChange('clientSecret', e.target.value)}
                                required
                              />
                            </FormGroup>
                            <FormGroup>
                              <label className='form-control-label' htmlFor='input-number'>
                                Mail Username
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-number'
                                placeholder='Mail Username'
                                value={''}
                                onChange={(e) => handleChange('clientSecret', e.target.value)}
                                required
                              />
                            </FormGroup>
                            <FormGroup>
                              <label className='form-control-label' htmlFor='input-number'>
                                Mail Password
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-number'
                                placeholder='Mail Password'
                                value={''}
                                onChange={(e) => handleChange('clientSecret', e.target.value)}
                                required
                              />
                            </FormGroup>
                            <FormGroup>
                              <label className='form-control-label' htmlFor='input-number'>
                                Mail Secure
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-number'
                                placeholder='Mail Secure'
                                value={''}
                                onChange={(e) => handleChange('clientSecret', e.target.value)}
                                required
                              />
                            </FormGroup>
                            <FormGroup>
                              <label className='form-control-label' htmlFor='input-number'>
                                Mail Address
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-number'
                                placeholder='Mail Address'
                                value={''}
                                onChange={(e) => handleChange('clientSecret', e.target.value)}
                                required
                              />
                            </FormGroup>
                            <FormGroup>
                              <label className='form-control-label' htmlFor='input-number'>
                                Mail Sender Name
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-number'
                                placeholder='Mail Sender Name'
                                value={''}
                                onChange={(e) => handleChange('clientSecret', e.target.value)}
                                required
                              />
                            </FormGroup>
                            <Button
                              className='btn-icon btn-3'
                              color='success'
                              type='button'
                              onClick={handlePaypalCreds}
                            >
                              <i className='fas fa-save' />
                              <span className='btn-inner--text'>Save</span>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <br />
                    </Form>
                  </TabPane> */}
                  <TabPane tabId='tabs3'>
                    <p className='description'>
                      <Form>
                        <br />
                        <div className='pl-lg-4'>
                          <Row>
                            <Col lg='8'>
                              <FormGroup>
                                <label className='form-control-label' htmlFor='input-title'>
                                  {t('New Password')}
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-title'
                                  placeholder='********'
                                  type='password'
                                  onChange={(e) => handleChange('password', e.target.value)}
                                  required
                                />
                              </FormGroup>
                              <FormGroup>
                                <label className='form-control-label'>{t('New Password Confirm')}</label>
                                <Input
                                  className='form-control-alternative'
                                  placeholder='********'
                                  type='password'
                                  onChange={(e) => handleChange('confirmPassword', e.target.value)}
                                  required
                                />
                              </FormGroup>
                              <Button
                                className='btn-icon btn-3'
                                color='success'
                                type='button'
                                onClick={handleUpdatePassword}
                              >
                                <i className='fas fa-save' />
                                <span className='btn-inner--text'>{t('Update')}</span>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <br />
                      </Form>
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </CardBody>
          {/* <CardFooter className='text-center'>
            <Button className='btn-icon btn-3' color='success' type='button' onClick={handlePaypalCreds}>
              <i className='fas fa-hands-helping'></i>
              <span className='btn-inner--text'>Send</span>
            </Button>
          </CardFooter> */}
        </Card>
      </Container>
    </>
  );
};

export default SettingsPage;
