import './CustomAlert.scss';

import React, { useContext } from 'react';

import { Alert } from 'reactstrap';
import AlertContext from 'context/AlertContext';

function CustomAlert() {
  const { alertData } = useContext(AlertContext);

  return (
    <Alert color={alertData.success ? 'success' : 'danger'} className='custom-alert' isOpen={alertData.show}>
      <span className='alert-inner--icon mr-2'>
        {alertData.success ? <i className='fas fa-check-circle' /> : <i className='fas fa-hand-paper' />}
      </span>
      <span className='alert-inner--text'>{alertData.message}</span>
    </Alert>
  );
}

export default CustomAlert;
