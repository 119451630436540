// reactstrap components
import {
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { Link, NavLink as NavLinkRRD } from 'react-router-dom';

// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
/*eslint-disable*/
import React from 'react';
import UserContext from '../../context/UserContext';
import config from '../../config';
// i18n
import { withTranslation } from 'react-i18next';

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
  };

  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes, user) => {
    return routes.map((prop, key) => {
      return prop.layout === '/admin' &&
        !prop.admin &&
        !prop.hide &&
        (user.userType === 'superAdmin' ? prop.name !== 'Help' && prop.name !== 'Premium' : true) ? (
        <NavItem key={key}>
          <NavLink to={prop.layout + prop.path} tag={NavLinkRRD} onClick={this.closeCollapse} activeClassName='active'>
            <i className={prop.icon} />
            {this.props.t(prop.name)}
          </NavLink>
        </NavItem>
      ) : null;
    });
  };

  createAdminLinks = (routes, user) => {
    return routes.map((prop, key) => {
      return prop.layout === '/admin' &&
        prop.admin &&
        prop.name !== 'Edit Event' &&
        (user.userType === 'superAdmin' ? true : prop.name !== 'Manage Users') ? (
        <NavItem key={key}>
          <NavLink to={prop.layout + prop.path} tag={NavLinkRRD} onClick={this.closeCollapse} activeClassName='active'>
            <i className={prop.icon} />
            {this.props.t(prop.name)}
          </NavLink>
        </NavItem>
      ) : null;
    });
  };

  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank',
      };
    }

    return (
      <UserContext.Consumer>
        {({ user }) => {
          return (
            <Navbar className='navbar-vertical fixed-left navbar-light bg-white' expand='md' id='sidenav-main'>
              <Container fluid>
                {/* Toggler */}
                <button className='navbar-toggler' type='button' onClick={this.toggleCollapse}>
                  <span className='navbar-toggler-icon' />
                </button>
                {/* Brand */}
                {logo ? (
                  <NavbarBrand className='pt-0' {...navbarBrandProps}>
                    <img alt={logo.imgAlt} className='navbar-brand-img' src={logo.imgSrc} />
                  </NavbarBrand>
                ) : null}
                {/* User */}
                <Nav className='align-items-center d-md-none'>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <Media className='align-items-center'>
                        <span className='avatar avatar-sm rounded-circle'>
                          {user?.image ? (
                            <img alt='profile' src={user.image} />
                          ) : (
                            <i className='fas fa-user-tie text-primary' style={{ fontSize: '18px' }} />
                          )}
                        </span>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className='dropdown-menu-arrow' right>
                      <DropdownItem href='#' onClick={(e) => e.preventDefault()}>
                        <i className='ni ni-user-run' />
                        <span>{this.props.t('Logout')}</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                {/* Collapse */}
                <Collapse navbar isOpen={this.state.collapseOpen}>
                  {/* Collapse header */}
                  <div className='navbar-collapse-header d-md-none'>
                    <Row>
                      {logo ? (
                        <Col className='collapse-brand' xs='6'>
                          {logo.innerLink ? (
                            <Link to={logo.innerLink}>
                              <img alt={logo.imgAlt} src={logo.imgSrc} />
                            </Link>
                          ) : (
                            <a href={logo.outterLink}>
                              <img alt={logo.imgAlt} src={logo.imgSrc} />
                            </a>
                          )}
                        </Col>
                      ) : null}
                      <Col className='collapse-close' xs='6'>
                        <button className='navbar-toggler' type='button' onClick={this.toggleCollapse}>
                          <span />
                          <span />
                        </button>
                      </Col>
                    </Row>
                  </div>
                  {/* Form */}
                  <Form className='mt-4 mb-3 d-md-none'>
                    {/* <InputGroup className='input-group-rounded input-group-merge'>
                <Input aria-label='Search' className='form-control-rounded form-control-prepended' placeholder='Search' type='search' />
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>
                    <span className='fa fa-search' />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup> */}
                  </Form>
                  {/* Navigation */}
                  <Nav navbar>{this.createLinks(routes, user)}</Nav>

                  {/* Admin links */}
                  {/* Divider */}
                  <hr className='my-3' />
                  <Nav navbar>{this.createAdminLinks(routes, user)}</Nav>
                  {/* Divider */}
                  <hr className='my-3' />

                  <Nav className='mb-md-3' navbar>
                    <NavItem className='active-pro active'>
                      <div className='d-flex justify-content-around'>
                        <NavLink href='https://docs.leventix.de' className='p-0' target='_blank'>
                          <div className={`icon icon-shape bg-red text-white rounded-circle shadow`}>
                            <i className='fas fa-book-open' />
                          </div>
                        </NavLink>
                        <NavLink href='https://www.leventix.de/datenschutzerklaerung/' className='p-0' target='_blank'>
                          <div className={`icon icon-shape bg-red text-white rounded-circle shadow`}>
                            <i className='fas fa-user-shield' />
                          </div>
                        </NavLink>
                        <NavLink href='https://www.leventix.de/impressum/' className='p-0' target='_blank'>
                          <div className={`icon icon-shape bg-red text-white rounded-circle shadow`}>
                            <i className='fas fa-receipt' />
                          </div>
                        </NavLink>
                      </div>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default withTranslation()(Sidebar);
