import React, { useState, useEffect } from 'react';
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
// i18n
import { useTranslation } from 'react-i18next';
import './Header.scss';

const Header = ({ hideStats, statsData }) => {
  const { t } = useTranslation();

  const [Stats, setStats] = useState([]);

  useEffect(() => {
    if (!hideStats) {
      const result = [];
      for (const key in statsData) {
        if (key === 'allUsers') {
          result.push({
            label: t('ACTIVE USERS'),
            value: statsData[key],
            icon: 'fas fa-users',
            color: 'warning',
          });
        } else if (key === 'allEvents') {
          result.push({
            label: t('ALL EVENTS'),
            value: statsData[key],
            icon: 'fas fa-chart-bar',
            color: 'danger',
          });
        } else if (key === 'freeEvents') {
          result.push({
            label: t('FREE EVENTS'),
            value: statsData[key],
            icon: 'fas fa-shoe-prints',
            color: 'warning',
          });
        } else if (key === 'paidEvents') {
          result.push({
            label: t('PAID EVENTS'),
            value: statsData[key],
            icon: 'fas fa-hand-holding-usd',
            color: 'purple',
          });
        } else if (key === 'organizers') {
          result.push({
            label: t('ORGANIZERS'),
            value: statsData[key],
            icon: 'fas fa-user-tie',
            color: 'purple',
          });
        } else if (key === 'eventInquiries') {
          result.push({
            label: t('EVENT INQUIRIES'),
            value: statsData[key],
            icon: 'fas fa-headset',
            color: 'green',
          });
        } else if (key === 'ticketsSold') {
          result.push({
            label: t('TICKETS SOLD'),
            value: statsData[key],
            icon: 'fas fa-ticket-alt',
            color: 'red',
          });
        } else if (key === 'totalMoney') {
          // let tm = '';
          // for (const key1 in statsData[key]) {
          //   tm += `${statsData[key][key1]} ${key1}<br/>`;
          // }
          // value: <div dangerouslySetInnerHTML={{ __html: tm }}></div>,
          const data = Object.entries(statsData[key]);
          result.push({
            label: `${t('TOTAL MONEY')} ${data?.[0]?.[0] || ''}`,
            value: data?.[0]?.[1] || 0,
            icon: 'far fa-money-bill-alt',
            color: 'info',
          });
          if (data.length > 1) {
            result.push({
              label: `${t('TOTAL MONEY')} ${data?.[1]?.[0] || ''}`,
              value: data?.[1]?.[1] || 0,
              value: statsData[key],
              icon: 'far fa-money-bill-alt',
              color: 'info',
            });
          }
        }
      }

      setStats(result);
    }
  }, [hideStats, t, statsData]);

  return (
    <>
      <div className='header bg-gradient-app pb-8 pt-5 pt-md-8'>
        <Container fluid>
          <div className='header-body'>
            {/* Card stats */}
            <Row className={hideStats ? 'd-none' : ''}>
              {Stats?.map((st, index) => (
                <Col lg='6' xl='4' className='pb-4' key={index}>
                  <Card className='card-stats mb-4 mb-xl-0'>
                    <CardBody>
                      <Row>
                        <div className='col'>
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 stat-heading'>
                            {st.label}
                          </CardTitle>
                          {/* <span className={`h1 font-weight-bold mb-0 ${st.label === t('TOTAL MONEY') ? 'text-sm' : ''}`}>{st.value}</span> */}
                          <span className={`h1 font-weight-bold mb-0`}>{st.value}</span>
                        </div>
                        <Col className='col-auto'>
                          <div className={`icon icon-shape bg-${st.color} text-white rounded-circle shadow`}>
                            <i className={st.icon} />
                          </div>
                        </Col>
                      </Row>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        {/* <span className='text-success mr-2'>
                          <i className='fa fa-arrow-up' /> 3.48%
                          </span>{' '}
                        <span className='text-nowrap'>Since last month</span> */}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
