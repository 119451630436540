import React from 'react';
// javascript plugin for creating charts
import Chart from 'chart.js';
// core components
import {
  chartOptions,
  parseOptions,
  // chartExample1,
  // chartExample2
} from 'variables/charts.js';

import Header from '../components/Headers/Header.js';
import { dashboard } from '../network/ApiAxios';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statsData: null,
      activeNav: 1,
      chartExample1Data: 'data1',
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data: this.state.chartExample1Data === 'data1' ? 'data2' : 'data1',
    });
  };

  getDashboardStats = async () => {
    try {
      const { data } = await dashboard();
      this.setState({ statsData: data });
    } catch (error) {
      console.log(error);
      // React.useContext(AlertContext).updateAlertData({
      //   success: false,
      //   message: error.msg || 'Unknown Error Occurred!',
      // });
    }
  };

  componentDidMount() {
    // initial load
    this.getDashboardStats();
  }

  render() {
    return <Header statsData={this.state.statsData} />;
  }
}

export default Index;
