import React, { useState, useContext, useEffect, useRef } from 'react';
// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col, Form, Input, Button } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
// i18n
import { useTranslation } from 'react-i18next';
// core components
import Header from 'components/Headers/Header.js';
import AlertContext from 'context/AlertContext';
import UserContext from 'context/UserContext';
// api's
import { chatInbox, fetchGroupMessages, sendChatMessage } from '../network/ApiAxios';

const Chat = () => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const { updateAlertData } = useContext(AlertContext);

  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputReply, setInputReply] = useState('');

  const messageListRef = useRef();

  // chatInbox
  const fetchInbox = async () => {
    try {
      const { data } = await chatInbox();
      setChats(data);
      if (data && data.length) {
        setSelectedChat(data[0]);
      }
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  useEffect(() => {
    fetchInbox();

    // refetch chat
    const refetchChat = setInterval(() => {
      getGroupMessages(selectedChat.groupId);
    }, 60000);

    return () => {
      clearInterval(refetchChat);
    };
  }, []);

  // chatInbox
  const getGroupMessages = async (groupId) => {
    try {
      const { data } = await fetchGroupMessages(groupId);
      setMessages([]);
      setMessages(data);
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  useEffect(() => {
    if (selectedChat) {
      getGroupMessages(selectedChat.groupId);
      setTimeout(() => {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight - messageListRef.current.clientHeight;
      }, 100);
    }
  }, [selectedChat]);

  // send reply
  const sendReply = async (e) => {
    try {
      const newChats = _.cloneDeep(chats);
      e.preventDefault();
      setIsLoading(true);

      const userReply = inputReply.trim();
      if (!userReply || userReply.trim().length < 0) {
        updateAlertData({ success: false, message: 'Message is required!' });
        setIsLoading(false);
        return;
      }
      await sendChatMessage({
        groupId: selectedChat.groupId,
        senderId: user._id,
        recipientId: selectedChat.userInfo._id,
        message: userReply,
      });
      setIsLoading(false);

      const chatIndex = newChats.findIndex((x) => x.groupId === selectedChat.groupId);
      if (chatIndex > -1) {
        newChats[chatIndex].lastMessage = userReply;
        setChats(newChats);
      }
      // empty inputReply
      setInputReply('');
      // refetch chat
      getGroupMessages(selectedChat.groupId);

      setTimeout(() => {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight - messageListRef.current.clientHeight;
      }, 100);
    } catch (error) {
      setIsLoading(false);
      updateAlertData({ success: false, message: error.message || error.msg || 'Unknown Error Occurred!' });
    }
  };

  return (
    <>
      <Header hideStats={true} />
      {/* Page content */}
      <Container className='mt--7 chat-wrapper' fluid>
        <Card className='bg-secondary shadow'>
          <CardHeader className='bg-white border-0'>
            <Row className='align-items-center'>
              <Col xs='8'>
                <h3 className='mb-0'>{t('Inbox')}</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xl='4' xs='6' className='border-right'>
                <ul className='list-group list-group-flush'>
                  {chats.map((ib) => (
                    <li
                      key={ib.groupId}
                      className={`list-group-item list-group-item-action flex-column align-items-start ${
                        selectedChat?.groupId === ib.groupId ? 'active' : ''
                      }`}
                      onClick={() => setSelectedChat(ib)}
                    >
                      <div className='d-flex flex-row w-100'>
                        {/* <img
                          src='https://m0.her.ie/wp-content/uploads/2018/01/07093633/GettyImages-887815620.jpg'
                          alt='user profile'
                          className='rounded-circle mr-3'
                          width='50'
                          height='50'
                        /> */}

                        <i className='fas fa-user-circle rounded-circle mr-3' style={{ fontSize: '44px' }} />
                        <div className='w-100'>
                          <div className='d-flex w-100 justify-content-between'>
                            <p className='font-weight-bold mb-0'>
                              {ib.userInfo?.firstName} {ib.userInfo?.lastName}
                            </p>
                            <small>{moment(ib.created).fromNow(true)}</small>
                          </div>
                          {/* <small className='mb-1 last-message'>{ib.lastMessage}</small> */}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col xl='8' xs='12' style={{ position: 'relative' }}>
                <div
                  className='d-flex flex-column'
                  style={{ height: 'calc(100vh - 400px)', overflowY: 'scroll', paddingBottom: '80px' }}
                  ref={messageListRef}
                >
                  {selectedChat
                    ? messages.map((msg, i) => (
                        <div className={`${user._id === msg.senderId || user._id === msg.receiverId ? 'user-message' : 'partner-message'}`} key={i}>
                          <div className='d-flex'>
                            <p className='card-text'>{msg.message}</p>
                          </div>
                        </div>
                      ))
                    : null}
                </div>

                {selectedChat ? (
                  <Form
                    className='d-flex flex-row align-items-center send-msg-wrapper'
                    style={{
                      background: '#f7fafc',
                      position: 'absolute',
                      width: 'calc(100% - 30px)',
                      bottom: '0',
                    }}
                  >
                    <Input
                      className='form-control'
                      placeholder='Aa'
                      rows='2'
                      type='textarea'
                      name='userReply'
                      value={inputReply}
                      onChange={(e) => setInputReply(e.target.value)}
                      required
                    />

                    <Button className='btn-icon btn-3 ml-3' color='primary' onClick={sendReply} disabled={isLoading}>
                      <span className='btn-inner--text'>{t('Send')}</span>
                      <span className='btn-inner--icon'>
                        <i className='fas fa-paper-plane' />
                      </span>
                    </Button>
                  </Form>
                ) : null}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Chat;
