import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AuthRoutes = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !(localStorage.getItem('token') && user) || rest?.location?.pathname?.includes('confirm-email') ? (
        <Component {...props} />
      ) : (
        <Redirect to='/admin/index' />
      )
    }
  />
);

export default AuthRoutes;
