/*eslint-disable*/
import React from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

const Login = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer className='py-5'>
        <Container>
          <Row className='align-items-center justify-content-xl-between'>
            <Col xl='6'>
              <div className='copyright text-center text-xl-left text-muted'>
                © {new Date().getFullYear()}{' '}
                <a className='font-weight-bold ml-1' href='https://www.leventix.de/' target='_blank'>
                  Leventix
                </a>
              </div>
            </Col>
            <Col xl='6'>
              <Nav className='nav-footer justify-content-center justify-content-xl-end'>
                <NavItem>
                  <NavLink href='https://www.leventix.de/impressum' className='text-white' target='_blank'>
                    Impressum
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='https://www.leventix.de/datenschutzerklaerung' className='text-white' target='_blank'>
                    {t('Data Privacy')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='https://www.leventix.de/' className='text-white' target='_blank'>
                    Leventix
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='https://www.leventix.de/' className='text-white' target='_blank'>
                    {t('About Us')}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
