import React, { useState, useContext, useEffect } from 'react';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Table,
  Button,
  Modal,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
// i18n
import { useTranslation } from 'react-i18next';
// core components
import Header from 'components/Headers/Header.js';
import AlertContext from 'context/AlertContext';
// api's
import { listTicketSales, updateTicketSales } from '../network/ApiAxios';

const ManageTicketSales = () => {
  const { t } = useTranslation();

  const { updateAlertData } = useContext(AlertContext);

  const [tabs, setTabs] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [selectedTS, setSelectedTS] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // fetchTicketSales
  const getTicketSales = async () => {
    try {
      const response = await listTicketSales();
      const { data } = response;
      const groupedData = _.groupBy(data, (o) => o.eventId.ticketType);
      setTransactions(groupedData);
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  useEffect(() => {
    getTicketSales();
  }, []);

  // submit update ticket sale
  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      // const ticketSale = { ...selectedTS };
      const ticketSale = { _id: selectedTS._id, shippingDetails: selectedTS.shippingDetails };
      ticketSale.trackingNumber = e.target.trackingNumber.value;
      ticketSale.shippingDetails.recipient_name = e.target.recipient_name.value;
      ticketSale.shippingDetails.line1 = e.target.line1.value;
      ticketSale.shippingDetails.city = e.target.city.value;
      ticketSale.shippingDetails.state = e.target.state.value;
      ticketSale.shippingDetails.postal_code = e.target.postal_code.value;

      await updateTicketSales(selectedTS._id, ticketSale);

      updateAlertData({ success: true, message: 'Updated successfully!' });

      setIsLoading(false);
      // refetch TicketSales
      getTicketSales();
      // close modal
      setSelectedTS(null);
      // if (!e.target.description.value || e.target.description.value.length < 3) {
      //   updateAlertData({ success: false, message: 'Please check the Description Entered!' });
      //   setIsLoading(false);
      //   return;
      // }
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  return (
    <>
      <Header hideStats={true} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Card className='bg-secondary shadow'>
          <CardHeader className='bg-white border-0'>
            <Row className='align-items-center'>
              <Col xs='8'>
                <h3 className='mb-0'>{t('Ticket Sales')}</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className='nav-wrapper'>
              <Nav className='nav-fill flex-column flex-md-row' id='tabs-icons-text' pills role='tablist'>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames('mb-sm-3 mb-md-0 font-weight-bold', { active: tabs === 1 })}
                    onClick={() => setTabs(1)}
                    role='tab'
                  >
                    <i className='fas fa-envelope mr-2' />
                    {t('Email Tickets')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 2}
                    className={classnames('mb-sm-3 mb-md-0 font-weight-bold', { active: tabs === 2 })}
                    onClick={() => setTabs(2)}
                    role='tab'
                  >
                    <i className='fab fa-usps mr-2' />
                    {t('Post Tickets')}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <Card className='shadow'>
              <CardBody>
                <TabContent activeTab={'tabs' + tabs}>
                  <TabPane tabId='tabs1'>
                    <Table className='align-items-center table-flush' responsive>
                      <thead className='thead-light'>
                        <tr>
                          <th scope='col'>{t('Transaction ID')}</th>
                          <th scope='col'>{t('Event Title')}</th>
                          <th scope='col'>{t('Contact')}</th>
                          <th scope='col'>{t('Amount')}</th>
                          <th scope='col'>{t('Created')}</th>
                          <th scope='col'>{t('Status')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions?.email?.map((et) => (
                          <tr key={et._id}>
                            <td>{et.transactionId}</td>
                            <td>{et.eventId.title}</td>
                            <td>
                              {et.userId?.firstName} {et.userId?.lastName}
                              {et.userId?.mobile ? ` - ${et.userId?.mobile}` : ''}
                              <br />
                              {et.userId?.email}
                            </td>
                            <td>
                              {et.amount} {et.currency}
                            </td>
                            <td>{moment(et.created).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>
                              <i className='fas fa-circle' style={{ fontSize: '20px', color: et.mailSent ? '#4caf50' : '#dd2e44' }} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TabPane>
                  {/* postTickets */}
                  <TabPane tabId='tabs2'>
                    <Table className='align-items-center table-flush' responsive>
                      <thead className='thead-light'>
                        <tr>
                          <th scope='col'>{t('Transaction ID')}</th>
                          <th scope='col'>{t('Event Title')}</th>
                          <th scope='col'>{t('Contact')}</th>
                          <th scope='col'>{t('Shipping Address')}</th>
                          <th scope='col'>{t('Amount')}</th>
                          <th scope='col'>{t('Created')}</th>
                          <th scope='col'>{t('Tracking Number')}</th>
                          <th scope='col'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {transactions?.post?.map((pt) => ( */}
                        {transactions?.email?.map((pt) => (
                          <tr key={pt._id}>
                            <td>{pt.transactionId}</td>
                            <td>{pt.eventId.title}</td>
                            <td>
                              {pt.userId?.firstName} {pt.userId?.lastName}
                              {pt.userId?.mobile ? ` - ${pt.userId?.mobile}` : ''}
                              <br />
                              {pt.userId?.email}
                            </td>
                            <td style={{ maxWidth: '400px' }}>
                              {pt.shippingDetails.recipient_name},{pt.shippingDetails.line1},{pt.shippingDetails.city},{pt.shippingDetails.state},
                              {pt.shippingDetails.country_code},{pt.shippingDetails.postal_code}
                            </td>
                            <td>
                              {pt.amount} {pt.currency}
                            </td>
                            <td>{moment(pt.created).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{pt.trackingNumber}</td>
                            <td>
                              <>
                                <Button className='btn-icon btn-2' color='warning' type='button' onClick={() => setSelectedTS(pt)}>
                                  <i className='fas fa-edit' />
                                </Button>
                              </>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </Container>

      {/* update ticket sale */}
      <Modal className='modal-dialog-centered' isOpen={!!selectedTS} toggle={() => setSelectedTS(null)} backdrop='static'>
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            {t('Update Ticket Sale - Shipping Address')}
          </h5>
          <button aria-label='Close' className='close' data-dismiss='modal' type='button' onClick={() => setSelectedTS(false)}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        {/* <div className='modal-body'> */}
        <div className=''>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='p-lg-4 card-body'>
              <Form role='form' method='post' onSubmit={formSubmit}>
                <FormGroup>
                  <label className='form-control-label' htmlFor='trackingNumber'>
                    {t('Tracking Number')}
                  </label>
                  <Input
                    className='form-control-alternative'
                    name='trackingNumber'
                    id='trackingNumber'
                    placeholder={t('Tracking Number')}
                    value={selectedTS?.trackingNumber}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <label className='form-control-label' htmlFor='recipient_name'>
                    {t('Recipient Name')}
                  </label>
                  <Input
                    className='form-control-alternative'
                    name='Recipient Name'
                    id='recipient_name'
                    placeholder={t('Recipient Name')}
                    value={selectedTS?.shippingDetails?.recipient_name}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <label className='form-control-label' htmlFor='line1'>
                    {t('Address')}
                  </label>
                  <Input
                    className='form-control-alternative'
                    name='line1'
                    id='line1'
                    placeholder={t('Address')}
                    value={selectedTS?.shippingDetails?.line1}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <label className='form-control-label' htmlFor='city'>
                    {t('City')}
                  </label>
                  <Input
                    className='form-control-alternative'
                    name='city'
                    id='city'
                    placeholder={t('City')}
                    value={selectedTS?.shippingDetails?.city}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <label className='form-control-label' htmlFor='state'>
                    {t('State')}
                  </label>
                  <Input
                    className='form-control-alternative'
                    name='state'
                    id='state'
                    placeholder={t('State')}
                    value={selectedTS?.shippingDetails?.state}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <label className='form-control-label' htmlFor='postal_code'>
                    {t('Postal Code')}
                  </label>
                  <Input
                    className='form-control-alternative'
                    name='postal_code'
                    id='postal_code'
                    placeholder={t('Postal Code')}
                    value={selectedTS?.shippingDetails?.postal_code}
                    required
                  />
                </FormGroup>
                <div className='text-center'>
                  <Button className='my-4' color='success' type='submit' disabled={isLoading}>
                    {t('Update')}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default ManageTicketSales;
