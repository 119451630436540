// reactstrap components
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';

import UserContext from 'context/UserContext';
// core components
import UserHeader from 'components/Headers/UserHeader.js';
import { useLocation } from 'react-router-dom';

const Profile = () => {
  const { user: selfUser } = useContext(UserContext);
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [viewMode, setViewMode] = useState(false);

  // update to show other user data
  useEffect(() => {
    if (location?.state?._id) {
      setViewMode(true);
      setUser(location.state);
    }
  }, [location]);

  // update to show me data
  useEffect(() => {
    if (!location?.state?._id) {
      setUser(selfUser);
    }
  }, [selfUser]);

  return (
    <>
      <UserHeader user={user} hideAction={viewMode} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Row>
          <Col className='mb-5 mb-xl-0' xl='12'>
            <Card className='card-profile shadow'>
              <Row className='justify-content-center'>
                <Col className='order-lg-2' lg='3'>
                  <div className='card-profile-image'>
                    {user?.image ? (
                      <img alt='profile' className='rounded-circle' src={user.image} style={{ background: 'white' }} />
                    ) : (
                      <div>
                        <i className='fas fa-user-tie text-primary pt-4' style={{ fontSize: '100px' }} />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              {/* <CardHeader className='border-0 pt-8 pt-md-4 pb-0 pb-md-4'>
                <div className='d-flex justify-content-between'>&nbsp;</div>
              </CardHeader> */}
              <CardBody className='pt-0 pt-md-4'>
                {/* <Row>
                  <div className='col'>
                    <div className='card-profile-stats d-flex justify-content-center mt-md-5'>
                      <div>
                        <span className='heading'>22</span>
                        <span className='description'>Events</span>
                      </div>
                      <div>
                        <span className='heading'>10</span>
                        <span className='description'>Friends</span>
                      </div>
                      <div>
                        <span className='heading'>89</span>
                        <span className='description'>Comments</span>
                      </div>
                    </div>
                  </div>
                </Row> */}
                <div style={{ minHeight: '100px' }}>
                  <div>
                    {user?.displayName ? (
                      <h3>
                        <i className='fas fa-file-signature mr-2' />
                        {user?.displayName}
                      </h3>
                    ) : null}
                    {user?.firstName || user?.lastName ? (
                      <h3>
                        <i className='fas fa-user mr-2' />
                        {user?.firstName} {user?.lastName}
                      </h3>
                    ) : null}
                    {user?.address || user?.city ? (
                      <div className='mt-3'>
                        <i className='fas fa-map-marked-alt mr-2' />
                        {user?.address}, {user?.city}
                      </div>
                    ) : null}
                    {user?.mobile ? (
                      <div className='mt-3'>
                        <i className='fas fa-phone-alt mr-2' />
                        {user?.mobile}
                      </div>
                    ) : null}
                    {user?.email ? (
                      <div className='mt-3'>
                        <i className='ni ni-email-83 mr-2' />
                        <a href={`mailto:${user?.email}`} target='_blank'>
                          {user?.email}
                        </a>
                      </div>
                    ) : null}
                    {/* {user?.organizerDetails?.company ? (
                      <h3>
                        <i className='fas fa-building mr-2' />
                        {user?.organizerDetails?.company}
                      </h3>
                    ) : null} */}
                    {user?.organizerDetails?.website ? (
                      <>
                        <i className='fas fa-globe-europe mr-2' />
                        <a href={user?.organizerDetails?.website} target='_blank'>
                          {user?.organizerDetails?.website}
                        </a>
                      </>
                    ) : null}
                  </div>
                  {/* {user?.organizerDetails?.taxNumber ? (
                    <div className='mt-3'>
                      <i className='fas fa-money-check mr-2' />
                      {user?.organizerDetails?.taxNumber}
                    </div>
                  ) : null} */}
                </div>

                <div>
                  {user?.organizerDetails?.socialMedia ? (
                    <div className='h5 mt-4'>
                      <i className='ni business_briefcase-24 mr-2' />
                      <a href={user?.organizerDetails?.socialMedia} target='_blank'>
                        {user?.organizerDetails?.socialMedia}
                      </a>
                    </div>
                  ) : null}
                  <hr className='my-4' />
                  <p>{user?.aboutMe}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
