import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// i18n
import { useTranslation } from 'react-i18next';

import { confirmRegister } from '../../network/ApiAxios';
import { Card, CardBody, Col } from 'reactstrap';

const ConfirmEmail = (props) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const [valid, setValid] = useState(false);
  const [error, setError] = useState();
  const [message, setMessage] = useState('Something went wrong!');

  useEffect(() => {
    if (!id) {
      props.history.push('/error');
    } else {
      const runAsync = async () => {
        try {
          const response = await confirmRegister(id);
          const { data } = response;
          if (data.success) {
            setValid(true);
            setError(false);
            setTimeout(() => {
              window.close();
            }, 5000);
          }
        } catch (e) {
          setValid(false);
          setError(true);
          setMessage(e.message);
        }
      };
      runAsync();
    }
  }, [id, props.history]);

  return (
    <>
      <Col lg='8' md='8'>
        <Card className='bg-secondary shadow border-0'>
          <CardBody className='px-lg-5 py-lg-5'>
            <div className='text-center mb-4'>
              <h1>{!error && (valid ? t('Email confirmed! You can login now!') : t('Verifying...'))}</h1>
              <h1>{error && t(message)}</h1>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ConfirmEmail;
