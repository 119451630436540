// reactstrap components
import { Button, Col, Container, Row } from 'reactstrap';
import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';
// i18n
import { useTranslation } from 'react-i18next';

const UserHeader = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <>
      <div
        className='header pb-8 pt-5 pt-lg-8 d-flex align-items-center'
        style={{
          minHeight: '600px',
          backgroundImage: `url(${props?.user?.backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        {/* Mask */}
        <span className='mask bg-gradient-default opacity-8' />
        {/* Header container */}
        <Container className='d-flex align-items-center' fluid>
          <Row>
            <Col>
              <h1 className='display-2 text-white'>
                {t('Hello')}, {props?.user?.displayName ? props?.user?.displayName : `${props?.user?.firstName} ${props?.user?.lastName}`}
              </h1>
              <p className='text-white mt-0 mb-5'>
                {/* This is your profile page. You can see the progress you've made with your work and manage your projects or assigned tasks */}
              </p>
              {props.hideAction ? null : props.edit ? (
                <Button color='info' onClick={() => history.push('/admin/user-profile')}>
                  {t('Back to your profile')}
                </Button>
              ) : (
                <Button color='info' onClick={() => history.push('/admin/edit-profile')}>
                  {t('Edit Profile')}
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
