import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import AuthFooter from 'components/Footers/AuthFooter.js';
import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import React from 'react';
import logoWhite from 'assets/img/brand/leventix-hoch-white.svg';
import errorLogoPng from 'assets/img/brand/error.png';

class Error extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-default');
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default');
  }

  render() {
    return (
      <>
        <div className='main-content'>
          <AuthNavbar />
          <div className='header bg-gradient-app py-7 py-lg-8'>
            <Container>
              <div className='header-body text-center mb-7'>
                <Row className='justify-content-center'>
                  <Col lg='5' md='6'>
                    <img alt='logo' src={logoWhite} width='180' />
                  </Col>
                </Row>
              </div>
            </Container>
            <div className='separator separator-bottom separator-skew zindex-100'>
              <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' version='1.1' viewBox='0 0 2560 100' x='0' y='0'>
                <polygon className='fill-default' points='2560 0 2560 100 0 100' />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className='mt--8 pb-5'>
            <Row className='justify-content-center'>
              <Col lg='6' md='8'>
                <Card className='shadow border-0'>
                  <CardBody className='text-center'>
                    <img src={errorLogoPng} style={{ width: '300px' }} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Error;
