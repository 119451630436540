import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Media,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { createHashTag, deleteHashtag, fetchUserHashTags, updateHashTag } from '../network/ApiAxios';

import AlertContext from 'context/AlertContext';
import UserContext from 'context/UserContext';
import Header from 'components/Headers/Header.js';
import { useTranslation } from 'react-i18next';

const HashTag = () => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const { updateAlertData } = useContext(AlertContext);

  const [hashTags, setHashTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(null);
  const [hashTagImage, setHashTagImage] = useState(null);

  // fetchUserHashTags
  const getHashTags = async () => {
    try {
      const { data } = await fetchUserHashTags();
      setHashTags(data);
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // save image onchange to state
  const uploadHandler = (event) => {
    setHashTagImage(event.target.files[0]);
  };

  // submit create form
  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      if (!e.target.name.value || e.target.name.value.length < 3) {
        updateAlertData({ success: false, message: 'Please check the Name Entered!' });
        setIsLoading(false);
        return;
      }
      if (!e.target.description.value || e.target.description.value.length < 3) {
        updateAlertData({ success: false, message: 'Please check the Description Entered!' });
        setIsLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('title', e.target.name.value);
      formData.append('description', e.target.description.value);
      if (hashTagImage) {
        formData.append('image', hashTagImage);
      }

      const { data } = await createHashTag(formData);
      updateAlertData({ success: true, message: data.msg });
      setHashTagImage(null);
      setIsLoading(false);
      // refetch hashtags
      getHashTags();
      // close modal
      setModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      setHashTagImage(null);
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // submit update form
  const submitUpdateHashtag = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      // if (!e.target.name.value || e.target.name.value.length < 3) {
      //   updateAlertData({ success: false, message: 'Please check the Name Entered!' });
      //   setIsLoading(false);
      //   return;
      // }
      // if (!e.target.description.value || e.target.description.value.length < 3) {
      //   updateAlertData({ success: false, message: 'Please check the Description Entered!' });
      //   setIsLoading(false);
      //   return;
      // }

      const formData = new FormData();
      // formData.append('title', e.target.name.value);
      // formData.append('description', e.target.description.value);
      if (hashTagImage) {
        formData.append('image', hashTagImage);
      }

      const { data } = await updateHashTag(updateModalOpen._id, formData);
      updateAlertData({ success: true, message: data.msg });
      setHashTagImage(null);
      setIsLoading(false);
      // refetch hashtags
      getHashTags();
      // close modal
      setUpdateModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      setHashTagImage(null);
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // handleApproveHashTag
  const handleApproveHashTag = async (id) => {
    try {
      const formData = new FormData();
      formData.append('isApproved', true);
      await updateHashTag(id, formData);
      // refetch hashtags
      getHashTags();
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // handleDeleteHashTag
  const handleDeleteHashTag = async (id) => {
    try {
      const { data } = await deleteHashtag(id);
      // refetch hashtags
      getHashTags();
      updateAlertData({ success: true, message: t(data.msg) });
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  // initial load
  useEffect(() => {
    getHashTags();
  }, []);

  return (
    <>
      <Header hideStats={true} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        {/* Table */}
        <Row>
          <div className='col hashtags'>
            <Card className='shadow'>
              <CardHeader className='border-0'>
                {/* <h3 className='mb-0'>Card tables</h3> */}
                <Button className='btn-icon btn-3' color='primary' type='button' onClick={() => setModalOpen(true)}>
                  <i className='fas fa-plus-circle' />
                  <span className='btn-inner--text'>{t('Create HashTag')}</span>
                </Button>
              </CardHeader>
              <Table className='align-items-center table-flush' responsive>
                <thead className='thead-light'>
                  <tr>
                    <th scope='col'>{t('Image')}</th>
                    <th scope='col'>{t('Title')}</th>
                    <th scope='col'>{t('Description')}</th>
                    <th scope='col'>{t('Status')}</th>
                    <th scope='col' />
                  </tr>
                </thead>
                <tbody>
                  {hashTags?.map((ht) => (
                    <tr key={ht._id}>
                      <th scope='row'>
                        <Media className='align-items-center'>
                          <div className='avatar mr-3'>
                            <img alt={ht.tag} src={ht.image} />
                          </div>
                        </Media>
                      </th>
                      <td>{ht.title}</td>
                      <td>{ht.description}</td>
                      <td>
                        <Badge color='' className='badge-dot mr-4'>
                          {ht.isApproved ? (
                            <>
                              <i className='bg-success' /> {t('Approved')}
                            </>
                          ) : (
                            <>
                              <i className='bg-warning' /> {t('Pending Approval')}
                            </>
                          )}
                        </Badge>
                      </td>
                      <td className='text-right'>
                        {user.userType === 'superAdmin' ? (
                          <>
                            <Button
                              className='btn-icon btn-2'
                              color='info'
                              type='button'
                              onClick={() => setUpdateModalOpen(ht)}
                              disabled={!ht.isApproved}
                            >
                              <i className='fas fa-edit' />
                            </Button>
                            <Button
                              className='btn-icon btn-2'
                              color='danger'
                              type='button'
                              onClick={() => handleDeleteHashTag(ht._id)}
                              // disabled={!ht.isApproved}
                            >
                              <i className='fas fa-trash-alt' />
                            </Button>
                            <Button
                              className='btn-icon btn-2'
                              color='success'
                              type='button'
                              onClick={() => (ht.isApproved ? null : handleApproveHashTag(ht._id))}
                              disabled={ht.isApproved}
                            >
                              <i className='fas fa-check' />
                            </Button>
                          </>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CardFooter className='py-4'>
                {/* <nav aria-label='...'>
                  <Pagination className='pagination justify-content-end mb-0' listClassName='justify-content-end mb-0'>
                    <PaginationItem className='disabled'>
                      <PaginationLink href='#' onClick={(e) => e.preventDefault()} tabIndex='-1'>
                        <i className='fas fa-angle-left' />
                        <span className='sr-only'>Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className='active'>
                      <PaginationLink href='#' onClick={(e) => e.preventDefault()}>
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href='#' onClick={(e) => e.preventDefault()}>
                        2 <span className='sr-only'>(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href='#' onClick={(e) => e.preventDefault()}>
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href='#' onClick={(e) => e.preventDefault()}>
                        <i className='fas fa-angle-right' />
                        <span className='sr-only'>Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* create hashtag */}
      <Modal className='modal-dialog-centered' isOpen={modalOpen} toggle={() => setModalOpen(false)} backdrop='static'>
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            {t('Create HashTag')}
          </h5>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={() => {
              setModalOpen(false);
              setIsLoading(false);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        {/* <div className='modal-body'> */}
        <div className=''>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-5'>
              <Form role='form' method='post' onSubmit={formSubmit}>
                <FormGroup>
                  <label className='form-control-label' htmlFor='name'>
                    {t('Name')}
                  </label>
                  <Input className='form-control-alternative' name='name' id='name' placeholder='Name' required />
                </FormGroup>
                <FormGroup>
                  <label className='form-control-label' htmlFor='description'>
                    {t('Description')}
                  </label>
                  <Input className='form-control-alternative' name='description' id='description' placeholder='Description' required />
                </FormGroup>
                <FormGroup>
                  <label className='form-control-label' htmlFor='image'>
                    {t('Image')}
                  </label>
                  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', paddingBottom: '10px' }}>
                    <CustomInput type='file' id='image' name='image' className='form-control' onChange={uploadHandler} />
                  </div>
                </FormGroup>

                <div className='text-center'>
                  <Button className='my-4' color='success' type='submit' disabled={isLoading}>
                    {t('Create')}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>

      {/* update hashtag */}
      <Modal className='modal-dialog-centered' isOpen={updateModalOpen} toggle={() => setUpdateModalOpen(null)} backdrop='static'>
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            {t('Update HashTag')}
          </h5>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={() => {
              setUpdateModalOpen(null);
              setIsLoading(false);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        {/* <div className='modal-body'> */}
        <div className=''>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-5'>
              <Form role='form' method='post' onSubmit={submitUpdateHashtag}>
                <FormGroup>
                  <label className='form-control-label' htmlFor='name'>
                    {t('Name')}
                  </label>
                  <Input className='form-control-alternative' disabled value={updateModalOpen?.title} />
                </FormGroup>
                <FormGroup>
                  <label className='form-control-label' htmlFor='description'>
                    {t('Description')}
                  </label>
                  <Input className='form-control-alternative' disabled value={updateModalOpen?.description} />
                </FormGroup>
                {updateModalOpen?.image ? (
                  <div className='text-center'>
                    <img src={updateModalOpen?.image.replace(/\\/g, '/')} alt={updateModalOpen?.title} height='150px' />
                  </div>
                ) : null}
                <FormGroup>
                  <label className='form-control-label' htmlFor='image'>
                    {t('Image')}
                  </label>
                  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', paddingBottom: '10px' }}>
                    <CustomInput type='file' id='image' name='image' className='form-control' onChange={uploadHandler} />
                  </div>
                </FormGroup>

                <div className='text-center'>
                  <Button className='my-4' color='success' type='submit' disabled={isLoading}>
                    {t('Update')}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default HashTag;
