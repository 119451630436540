// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { deleteProfilePic, edit, updateProfilePic } from '../../network/ApiAxios';

import UserContext from '../../context/UserContext';
// core components
import UserHeader from '../../components/Headers/UserHeader';
import _ from 'lodash';
// i18n
import { useTranslation } from 'react-i18next';

const EditProfile = (props) => {
  const { t, i18n } = useTranslation();
  const { user, setUser } = useContext(UserContext);

  const [formData, setFormData] = useState({ ...user, ...user.organizerDetails });
  const [error, setError] = useState('');
  const [profileImage, setProfileImage] = useState(null);

  // save image onchange to state
  const uploadHandler = (event) => {
    setProfileImage(event.target.files[0]);
  };

  // handle form fields changes
  const handleChange = (name, value, organizer = false, account = false) => {
    const newFormData = _.cloneDeep(formData);
    if (organizer) {
      if (!newFormData['organizerDetails']) {
        newFormData['organizerDetails'] = {};
      }
      newFormData['organizerDetails'][name] = value;
    } else if (account) {
      if (!newFormData['accountSettings']) {
        newFormData['accountSettings'] = {};
      }
      newFormData['accountSettings'][name] = value;
    } else {
      newFormData[name] = value;
    }
    setFormData(newFormData);
  };

  const editUser = async () => {
    const updatedUser = _.pick(formData, ['accountSettings', 'organizerDetails', 'address', 'city', 'displayName', 'mobile', 'aboutMe']);
    let newUser = { ...user, ...updatedUser };
    // update profile-pic
    if (profileImage) {
      const payload = new FormData();
      payload.append('image', profileImage);
      const res = await updateProfilePic(payload);
      newUser.image = res.data;
    }

    const response = await edit(user._id, updatedUser);
    const { data } = response;
    if (data.success) {
      if (newUser?.accountSettings?.locale) {
        i18n.changeLanguage(newUser.accountSettings.locale);
      }

      setUser(newUser);
      props.history.push('/admin/user-profile');
    }
  };

  // delete user profile pic
  const deleteUserProfilePic = async () => {
    const { data } = await deleteProfilePic();
    if (data.success) {
      setUser({ ...user, image: null });
      props.history.push('/admin/edit-profile');
    }
  };

  return (
    <>
      <UserHeader user={user} edit={true} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Row>
          <div className='col'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>{/* <h3 className='mb-0'>&nbsp;</h3> */}</Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* <Form>
                  <h6 className='heading-small text-muted mb-4'>User information</h6>
                  <div className='pl-lg-4'>
                    <Row>
                      <Col lg='6'>
                        <FormGroup>
                          <label className='form-control-label' htmlFor='input-username'>
                            Username
                          </label>
                          <Input
                            className='form-control-alternative'
                            value={name}
                            id='input-username'
                            placeholder='Username'
                            onChange={(e) => setName(e.target.value)}
                            type='text'
                          />
                        </FormGroup>
                      </Col>
                      <Col lg='6'>
                        <FormGroup>
                          <label className='form-control-label' htmlFor='input-email'>
                            Email address
                          </label>
                          <Input
                            className='form-control-alternative'
                            id='input-email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type='email'
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form> */}
                <Form role='form' className='row'>
                  <Col xl='6'>
                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-title'>
                        {t('Organizer/ Company')}
                      </label>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-building' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('Organizer/ Company')}
                          type='text'
                          // value={formData?.company}
                          // onChange={(e) => handleChange('company', e.target.value, true)}
                          value={formData?.displayName}
                          onChange={(e) => handleChange('displayName', e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-title'>
                        {t('Website')}
                      </label>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-globe-europe' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('Website')}
                          type='email'
                          value={formData?.organizerDetails?.website}
                          onChange={(e) => handleChange('website', e.target.value, true)}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-title'>
                        {t('Address (street & nr.)')}
                      </label>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-map-marked-alt' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('Address (street & nr.)')}
                          type='text'
                          value={formData?.address}
                          onChange={(e) => handleChange('address', e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-title'>
                        {t('PLZ / City')}
                      </label>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-map-marker-alt' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('PLZ / City')}
                          type='text'
                          value={formData?.city}
                          onChange={(e) => handleChange('city', e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-title'>
                        {`${t('Social Media')} - Instagram`}
                      </label>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-share-square' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={`${t('Social Media')} - Instagram`}
                          value={formData?.organizerDetails?.instagram}
                          onChange={(e) => handleChange('instagram', e.target.value, true)}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-title'>
                        {`${t('Social Media')} - Facebook`}
                      </label>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-share-square' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={`${t('Social Media')} - Facebook`}
                          value={formData?.organizerDetails?.facebook}
                          onChange={(e) => handleChange('facebook', e.target.value, true)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col xl='6'>
                    {/* handle profile picture changes */}
                    <div className='col-12 p-0'>
                      <div className='col-6 d-flex align-items-center justify-content-center'>
                        <CustomInput type='file' id='image' name='image' className='form-control' onChange={uploadHandler} />
                      </div>
                      <div className='col-6 d-flex'>
                        {user?.image ? (
                          <>
                            <img
                              alt='profile'
                              className='img-thumbnail'
                              src={user.image}
                              style={{ background: 'white', height: '150px', width: 'auto', marginTop: '10px', marginBottom: '25px' }}
                            />
                            <i
                              className='fas fa-backspace text-danger pt-4 ml-2'
                              style={{ fontSize: '50px', cursor: 'pointer' }}
                              onClick={deleteUserProfilePic}
                            />
                          </>
                        ) : (
                          <div>
                            <i className='fas fa-user-tie text-primary pt-4' style={{ fontSize: '100px' }} />
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <FormGroup>
                      <label className='form-control-label' htmlFor='input-title'>
                        {t('Display Name')}
                      </label>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-user' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('Display Name')}
                          type='text'
                          value={formData?.displayName}
                          onChange={(e) => handleChange('displayName', e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup> */}

                    <FormGroup>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-phone-alt' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('Phone')}
                          type='tel'
                          value={formData?.mobile}
                          onChange={(e) => handleChange('mobile', e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-money-check' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('Tax Number')}
                          value={formData?.organizerDetails?.taxNumber}
                          onChange={(e) => handleChange('taxNumber', e.target.value, true)}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-title'>
                        {t('About Me')}
                      </label>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-share-square' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('More Information')}
                          type='textarea'
                          rows={3}
                          value={formData?.aboutMe}
                          onChange={(e) => handleChange('aboutMe', e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-title'>
                        {`${t('Language')}`}
                      </label>
                      <InputGroup className='input-group-alternative mb-3'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='fas fa-language' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <select
                          name='locale'
                          placeholder={`${t('Language')}`}
                          className='form-control-alternative form-control'
                          onChange={(e) => handleChange('locale', e.target.value, false, true)}
                          value={formData?.accountSettings?.locale}
                        >
                          <option value='en'>English</option>
                          <option value='de'>German</option>
                          <option value='es'>Spanish</option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  {error ? (
                    <div className='text-muted font-italic'>
                      <small>
                        Error: <span className='text-red font-weight-700'>{error}</span>
                      </small>
                    </div>
                  ) : null}

                  <div className='w-100 text-center'>
                    <Button onClick={editUser} className='mt-4' color='primary' type='button'>
                      {t('Update Profile')}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EditProfile;
