import React, { useState, useContext } from 'react';
// reactstrap components
import { Card, CardHeader, CardFooter, Container, Row, Button, CardBody, FormGroup, Form, Input, Col } from 'reactstrap';
import _ from 'lodash';
// i18n
import { useTranslation } from 'react-i18next';
// core components
import Header from 'components/Headers/Header.js';
import AlertContext from 'context/AlertContext';
import { sendMail } from '../network/ApiAxios';

const HelpPage = (props) => {
  const { t } = useTranslation();

  const { updateAlertData } = useContext(AlertContext);
  const [formData, setFormData] = useState({});

  // handle form fields changes
  const handleChange = (name, value) => {
    const newFormData = _.cloneDeep(formData);
    newFormData[name] = value;
    setFormData(newFormData);
  };

  // handleSend
  const handleSend = async () => {
    try {
      const newFormData = _.cloneDeep(formData);
      await sendMail('enquiry', newFormData);

      updateAlertData({ success: true, message: 'Success' });

      setTimeout(() => {
        props.history.replace('/');
      }, 3000);
    } catch (error) {
      console.log(error);
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!' });
    }
  };

  return (
    <>
      <Header hideStats={true} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Card className='bg-secondary shadow'>
          <CardHeader className='bg-white border-0'>
            <Row className='align-items-center'>
              <Col xs='8'>
                <h3 className='mb-0'>{t('Create Ticket')}</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <br />
              <div className='pl-lg-4'>
                <Row>
                  <Col lg='8'>
                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-subject'>
                        {t('Request To Support')}
                      </label>
                      <Input
                        className='form-control-alternative'
                        id='input-subject'
                        placeholder={t('Request To Support')}
                        onChange={(e) => handleChange('subject', e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label' htmlFor='input-number'>
                        {t('Phone Number')}
                      </label>
                      <Input
                        className='form-control-alternative'
                        id='input-number'
                        placeholder={t('Phone Number')}
                        type='number'
                        onChange={(e) => handleChange('mobile', e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>{t('Description')}</label>
                      <Input
                        className='form-control-alternative'
                        placeholder={t('Description')}
                        rows='5'
                        type='textarea'
                        onChange={(e) => handleChange('message', e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <br />
            </Form>
          </CardBody>
          <CardFooter className='text-center'>
            <Button className='btn-icon btn-3' color='success' type='button' onClick={handleSend}>
              <i className='fas fa-hands-helping'></i>
              <span className='btn-inner--text'>{t('Send')}</span>
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default HelpPage;
