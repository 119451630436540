// window.process = {};

import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import './i18n';
import './custom.scss';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './app';

ReactDOM.render(
  <Suspense fallback='loading'>
    <App />
  </Suspense>,
  document.getElementById('root')
);
