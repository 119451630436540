import { Alert, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { getAppPayment, updateAppPayment } from '../network/ApiAxios';

import config from '../config';
import logoWhite from 'assets/img/brand/leventix-hoch-white.svg';
import { useParams } from 'react-router-dom';

const Payment = () => {
  const [paymentId, setPaymentId] = useState(useParams()?.id);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const [alertData, updateAlertData] = useState({ show: false });

  // useEffect(() => {
  //   // let search = window.location.search.substring(1);
  //   // search = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', (key, value) =>
  //   //   key === '' ? value : decodeURIComponent(value)
  //   // );
  //   // setParams(search);
  //   const { id } = useParams();
  //   setPaymentId(id);
  // }, []);

  useEffect(() => {
    document.body.classList.add('bg-default');
    return () => {
      document.body.classList.remove('bg-default');
    };
  }, []);

  useEffect(() => {
    if (paymentId) {
      getPaymentInfo(paymentId);
    }
  }, [paymentId]);

  // getPaymentInfo
  const getPaymentInfo = async (id) => {
    try {
      const response = await getAppPayment(id);
      const { data } = response;
      if (data) {
        setPaymentInfo(data);
      }
    } catch (error) {
      updateAlertData({ success: false, message: error.msg || 'Unknown Error Occurred!', show: true });
    }
  };

  useEffect(() => {
    if (alertData.show) {
      setTimeout(() => {
        updateAlertData({ ...alertData, show: false });
      }, 5000);

      window.ReactNativeWebView?.postMessage(`${alertData.success}`);
    }
  }, [alertData]);

  // payment handler
  useEffect(() => {
    if (paymentInfo) {
      window.paypal.Button.render(
        {
          style: {
            color: 'blue',
            shape: 'pill',
            size: 'responsive',
            label: 'pay',
            tagline: true,
          },
          env: 'sandbox', // Or 'production'
          // Set up the payment:
          // 1. Add a payment callback
          payment: function (data, actions) {
            // 2. Make a request to your server
            return actions.request
              .post(`${config.WS_BASE_URL}payment/create-payment/`, {
                userId: paymentInfo.userId,
                eventId: paymentInfo.eventId,
                type: paymentInfo.transactionType,
                amount: paymentInfo.amount,
                tickets: JSON.stringify(paymentInfo.tickets),
                currency: paymentInfo.currency,
              })
              .then(function (res) {
                return res.id;
              })
              .catch((e1) => {
                console.log(e1);
                updateAlertData({ success: false, message: e1.msg || 'Unknown Error Occurred!', show: true });
              });
          },
          // Execute the payment:
          // 1. Add an onAuthorize callback
          onAuthorize: function (data, actions) {
            console.log(data, paymentInfo);
            // 2. Make a request to your server
            return actions.request
              .post(`${config.WS_BASE_URL}payment/execute-payment/`, {
                ...data,
                userId: paymentInfo.userId,
                eventId: paymentInfo.eventId,
                type: paymentInfo.transactionType,
                amount: paymentInfo.amount,
                tickets: JSON.stringify(paymentInfo.tickets),
                currency: paymentInfo.currency,
              })
              .then(function (res) {
                updateAppPayment(paymentId);
                // 3. Show the buyer a confirmation message.
                updateAlertData({ success: true, message: res.message || 'Transaction Successful!', show: true });
              })
              .catch((e2) => {
                console.log(e2);
                updateAlertData({ success: false, message: e2.msg || 'Unknown Error Occurred!', show: true });
              });
          },
        },
        'paypal-button'
      );
    }
  }, [paymentInfo]);

  return (
    <>
      <Alert color={alertData?.success ? 'success' : 'danger'} className='custom-alert' isOpen={alertData.show}>
        <span className='alert-inner--icon mr-2'>
          {alertData.success ? <i className='fas fa-check-circle' /> : <i className='fas fa-hand-paper' />}
        </span>
        <span className='alert-inner--text'>{alertData.message}</span>
      </Alert>

      <div className='main-content'>
        <div className='header bg-gradient-app py-7 py-lg-8'>
          <Container>
            <div className='header-body text-center mb-7'>
              <Row className='justify-content-center'>
                <Col lg='5' md='6'>
                  <img alt='logo' src={logoWhite} width='180' />
                </Col>
              </Row>
            </div>
          </Container>
          <div className='separator separator-bottom separator-skew zindex-100'>
            <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' version='1.1' viewBox='0 0 2560 100' x='0' y='0'>
              <polygon className='fill-default' points='2560 0 2560 100 0 100' />
            </svg>
          </div>
        </div>
        <Container className='mt--8 pb-5'>
          <Row className='justify-content-center'>
            <Col lg='5' md='7'>
              <Card className='bg-secondary shadow border-0'>
                <CardHeader className='bg-transparent pb-5'>
                  <div className='text-muted text-center mt-2 mb-3'></div>
                  <div className='btn-wrapper text-center'>
                    {paymentInfo ? (
                      <>
                        <p className='mb-3'>
                          <strong>
                            {paymentInfo.amount} {paymentInfo.currency}
                          </strong>
                        </p>
                        <div id='paypal-button'></div>
                      </>
                    ) : null}
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Payment;
