import axios from 'axios';
import config from '../config';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

// const https = require('https');

// const agent = new https.Agent({
//   rejectUnauthorized: false,
// });

const instance = axios.create({ baseURL: config.WS_BASE_URL });

// request interceptor
instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? token : '';
  config.headers.ContentType = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
  //   if (config.method === 'post' && config.url.includes('hashtags')) {
  //     config.headers.ContentType = 'multipart/form-data';
  //   }
  return config;
});
// response interceptor
instance.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log(err?.response?.data);

    if (err?.message?.includes(' 401')) {
      localStorage.removeItem('token');

      window?.location.replace('/auth/login');
    }
    return Promise.reject(err?.response?.data || 'Unknown Error Occurred!');
  }
);

// Dashboard
export const dashboard = async () => await instance.get('/dashboard/stats');
export const fetchNotifications = async () => await instance.get('/dashboard/notifications');

// users
export const getAll = async () => await instance.post('users/all');
export const register = async (data) => await instance.post('users/register', data);
export const confirmRegister = async (id) => await instance.post(`users/confirm/${id}`);
export const forgotPassword = async (email) => await instance.post('users/forgot-password', { email });
export const confirmReset = async (id, password) => await instance.post(`users/reset-password/${id}`, { password });
export const changePassword = async (password) => await instance.post(`users/change-password`, { password });
export const login = async (email, password) => await instance.post('users/login', { email, password, adminLogin: true });
export const socialLogin = async (data) => await instance.post('users/social-login', { ...data, adminLogin: true });
export const logout = async (token) => await instance.post('users/logout', { token });
export const edit = async (userID, body) => await instance.post('/users/edit', { userID, ...body });
export const userMyDetails = async () => await instance.get('/users/me');
export const deleteUser = async (id) => await instance.delete(`/users/${id}`);
export const deleteUserWithData = async (id) => await instance.delete(`/users/${id}/all`);
export const updateProfilePic = async (body) => await instance.post('/users/profile-pic', body);
export const deleteProfilePic = async () => await instance.delete('/users/profile-pic');

// hashtags
export const fetchHashTags = async () => await instance.get('/hashtags');
export const fetchUserHashTags = async () => await instance.get('/hashtags/me');
export const createHashTag = async (data) => await instance.post('/hashtags', data);
export const updateHashTag = async (id, data) => await instance.put(`/hashtags/${id}`, data);
export const deleteHashtag = async (id) => await instance.delete(`/hashtags/${id}`);

// Events
export const fetchEvents = async () => await instance.get('/events');
export const fetchUserEvents = async () => await instance.get('/events/me');
export const fetchAdminEventById = async (id) => await instance.get(`/events/admin/${id}`);
export const fetchEventById = async (id) => await instance.get(`/events/${id}`);
export const createEvent = async (data) => await instance.post('/events', data);
export const updateEvent = async (id, data) => await instance.put(`/events/${id}`, data);
export const deleteEvent = async (id) => await instance.delete(`/events/${id}`);

// Ticket Sales
export const listTicketSales = async () => await instance.get('/tickets/list');
export const updateTicketSales = async (id, data) => await instance.put(`/tickets/${id}`, data);

// Payment
export const createPayment = async (data) => await instance.post('/payment/create-payment', data);
export const executePayment = async (data) => await instance.post('/payment/execute-payment', data);

// Chat
export const chatInbox = async () => await instance.get('/chat/inbox');
export const fetchGroupMessages = async (id) => await instance.get(`/chat/messages/${id}`);
export const sendChatMessage = async (data) => await instance.post('/chat', data);

// App Payment
export const getAppPayment = async (id) => await instance.get(`/app-payment/${id}`);
export const updateAppPayment = async (id) => await instance.put(`/app-payment/${id}`);

// Mail
export const sendMail = async (type, data) => await instance.post(`/mail/${type}`, data);
