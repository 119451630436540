// reactstrap components
import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import React, { useState } from 'react';

import Toast from 'react-bootstrap/Toast';
import _ from 'lodash';
import leventixLogoPng from '../../assets/img/brand/leventix.png';
import { register } from '../../network/ApiAxios';
// i18n
import { useTranslation } from 'react-i18next';

const Register = (props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    password: '',
    confirmPassword: '',
    address: '',
    city: '',
    aboutMe: '',
    organizerDetails: {},
  });
  const [error, setError] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [showToast, setShowToast] = useState(false);

  // handle form fields changes
  const handleChange = (name, value, organizer = false) => {
    const newFormData = _.cloneDeep(formData);
    if (organizer) {
      if (!newFormData['organizerDetails']) {
        newFormData['organizerDetails'] = {};
      }
      newFormData['organizerDetails'][name] = value;
    } else {
      newFormData[name] = value;
    }
    setFormData(newFormData);
  };

  // registerUser
  const registerUser = async () => {
    try {
      setError('');
      const { firstName, lastName, email, mobile, password, confirmPassword, address, city, aboutMe, organizerDetails } = _.cloneDeep(formData);
      if (
        !(
          firstName &&
          lastName &&
          email &&
          mobile &&
          password &&
          confirmPassword &&
          checkbox &&
          address &&
          city &&
          organizerDetails &&
          organizerDetails.company &&
          organizerDetails.website
        )
      ) {
        setError(t('Make sure to fill all the inputs and agree to the privacy policy!'));
        return;
      }
      if (password !== confirmPassword) {
        setError(t('Entered Passwords do not match!'));
        return;
      }
      await register({ firstName, lastName, email, mobile, password, address, city, aboutMe, organizerDetails, userType: 'organizer' });
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
        address: '',
        city: '',
        aboutMe: '',
        organizerDetails: {},
      });
      setCheckbox(false);
      setShowToast(true);
      setTimeout(() => props.history.push('/auth/login'), 2000);
    } catch (error) {
      setError(t(error.msg || 'Unknown Error Occurred!'));
    }
  };

  return (
    <>
      <div
        aria-live='polite'
        aria-atomic='true'
        style={{
          position: 'fixed',
          minHeight: '100px',
          width: '35%',
          right: 10,
          bottom: 80,
          zIndex: 50,
        }}
      >
        <Toast
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'white',
            padding: 10,
            borderRadius: 10,
          }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={10000}
        >
          <Toast.Header>
            <img style={{ height: '50px', width: '50px' }} src={leventixLogoPng} alt='logo' />
          </Toast.Header>
          <Toast.Body>{t('Registration Successfully!')}</Toast.Body>
        </Toast>
      </div>
      <Col md='10' className='register-page'>
        <Card className='bg-secondary shadow border-0'>
          {/* <CardHeader className='bg-transparent pb-5'>
            <div className='text-muted text-center mt-2 mb-4'>
              <small>Sign up with</small>
            </div>
            <div className='text-center'>
              <Button
                className='btn-neutral btn-icon mr-4'
                color='default'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
              >
                <span className='btn-inner--icon'>
                  <img alt='...' src={require('assets/img/icons/common/facebook.svg').default} />
                </span>
                <span className='btn-inner--text'>Facebook</span>
              </Button>
              <Button
                className='btn-neutral btn-icon'
                color='default'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
              >
                <span className='btn-inner--icon'>
                  <img alt='...' src={require('assets/img/icons/common/google.svg').default} />
                </span>
                <span className='btn-inner--text'>Google</span>
              </Button>
            </div>
          </CardHeader> */}
          <CardBody className='px-lg-5 py-lg-5'>
            {/* <div className='text-center text-muted mb-4'>
              <small>Or sign up with credentials</small>
            </div> */}
            <Form role='form' className='row'>
              <Col xl='6'>
                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-building' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('Organizer/ Company')}
                      type='text'
                      // value={formData?.company}
                      // onChange={(e) => handleChange('company', e.target.value, true)}
                      value={formData?.displayName}
                      onChange={(e) => handleChange('displayName', e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-globe-europe' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('Website')}
                      type='email'
                      value={formData?.website}
                      onChange={(e) => handleChange('website', e.target.value, true)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-map-marked-alt' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('Address (street & nr.)')}
                      type='text'
                      value={formData?.address}
                      onChange={(e) => handleChange('address', e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-map-marker-alt' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder={t('PLZ / City')} type='text' value={formData?.city} onChange={(e) => handleChange('city', e.target.value)} />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-share-square' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={`${t('Social Media')} - Instagram`}
                      value={formData?.instagram}
                      onChange={(e) => handleChange('instagram', e.target.value, true)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-share-square' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={`${t('Social Media')} - Facebook`}
                      value={formData?.facebook}
                      onChange={(e) => handleChange('facebook', e.target.value, true)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col xl='6'>
                {/* <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-file-signature' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('Display Name')}
                      type='text'
                      value={formData?.displayName}
                      onChange={(e) => handleChange('displayName', e.target.value)}
                    />
                  </InputGroup>
                </FormGroup> */}
                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-user' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('First Name')}
                      type='text'
                      value={formData?.firstName}
                      onChange={(e) => handleChange('firstName', e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-user' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('Last Name')}
                      type='text'
                      value={formData?.lastName}
                      onChange={(e) => handleChange('lastName', e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-phone-alt' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder={t('Phone')} type='tel' value={formData?.mobile} onChange={(e) => handleChange('mobile', e.target.value)} />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-email-83' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder={t('Email')} type='email' value={formData?.email} onChange={(e) => handleChange('email', e.target.value)} />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-lock-circle-open' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('Password')}
                      type='password'
                      value={formData?.password}
                      onChange={(e) => handleChange('password', e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-lock-circle-open' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('Confirm Password')}
                      type='password'
                      value={formData?.confirmPassword}
                      onChange={(e) => handleChange('confirmPassword', e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='fas fa-share-square' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('More Information')}
                      type='textarea'
                      rows={4}
                      value={formData?.aboutMe}
                      onChange={(e) => handleChange('aboutMe', e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              {error ? (
                <div className='text-muted font-italic'>
                  <small>
                    Error: <span className='text-red font-weight-700'>{error}</span>
                  </small>
                </div>
              ) : null}

              <Col xs='12' className='mt-1 mb-2 text-center w-100'>
                <div className='custom-control custom-control-alternative custom-checkbox'>
                  <input
                    className='custom-control-input'
                    id='customCheckRegister'
                    type='checkbox'
                    checked={checkbox}
                    onChange={() => setCheckbox(!checkbox)}
                  />
                  <label className='custom-control-label' htmlFor='customCheckRegister'>
                    <span className='text-muted'>
                      {t('I agree with the')}{' '}
                      <a href='https://www.leventix.de/datenschutzerklaerung' target='_blank'>
                        {t('Privacy Policy')}
                      </a>
                    </span>
                  </label>
                </div>
              </Col>
              <div className='w-100 text-center'>
                <Button onClick={registerUser} className='mt-4' color='primary' type='button' disabled={!checkbox}>
                  {t('Create account')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className='mt-3'>
          <Col xs='6' />
          <Col className='text-right' xs='6'>
            <span className='text-light cursor-pointer' onClick={() => props.history.push('/auth/login')}>
              <small>{t('Sign In')}</small>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Register;
