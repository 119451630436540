import { Button, Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Form, Modal, Row } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';

import UserContext from 'context/UserContext';
import AlertContext from 'context/AlertContext';
import Header from 'components/Headers/Header.js';
import basicPlanImage from '../assets/img/brand/leventix-grey.svg';
import config from '../config';
import lightPlanImage from '../assets/img/brand/leventix-black.svg';
import moment from 'moment';
import premiumPlanImage from '../assets/img/brand/leventix.png';
import { sendMail } from '../network/ApiAxios';
import { useTranslation } from 'react-i18next';

const PlansPage = () => {
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);
  const { updateAlertData } = useContext(AlertContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [optedPlan, setOptedPlan] = useState();
  const [modalOpened, setModalOpened] = useState(false);

  const plans = [
    {
      title: 'basic',
      image: basicPlanImage,
      charges: '0.00',
      features: [],
    },
    {
      title: 'light',
      image: lightPlanImage,
      charges: '39.99',
      features: ['Sell Tickets', 'Manage Payments'],
    },
    {
      title: 'premium',
      image: premiumPlanImage,
      charges: '59.99',
      features: ['Sell Tickets', 'Manage Payments', 'Favorite Events'],
    },
  ];

  // payment handler
  useEffect(() => {
    if (modalOpened) {
      window.paypal.Button.render(
        {
          env: 'sandbox', // Or 'production'
          // Set up the payment:
          // 1. Add a payment callback
          payment: function (data, actions) {
            // 2. Make a request to your server
            return actions.request
              .post(`${config.WS_BASE_URL}payment/create-payment/`, {
                userId: user._id,
                type: 'USER_SUBSCRIPTION',
                amount: optedPlan?.charges,
                currency: 'EUR',
              })
              .then(function (res) {
                return res.id;
              })
              .catch((e1) => {
                updateAlertData({ success: false, message: e1.msg || 'Unknown Error Occurred!' });
              });
          },
          // Execute the payment:
          // 1. Add an onAuthorize callback
          onAuthorize: function (data, actions) {
            console.log(data, user);
            // 2. Make a request to your server
            return actions.request
              .post(`${config.WS_BASE_URL}payment/execute-payment/`, {
                ...data,
                userId: user._id,
                type: 'USER_SUBSCRIPTION',
                optedPlan: optedPlan.title,
                amount: optedPlan?.charges,
                currency: 'EUR',
              })
              .then(function (res) {
                // 3. Show the buyer a confirmation message.
                updateAlertData({ success: true, message: res.message || 'Transaction Successful!' });

                sendMail('plans', { plan: optedPlan.title, price: `${optedPlan.charges} €`, username: `${user.firstName} ${user.lastName}` });

                // update user
                setUser({ ...user, userSubscription: optedPlan.title, userSubscriptionExpiry: moment().add(30, 'days').format() });
                // close modal
                setModalOpen(false);
                setModalOpened(false);
              })
              .catch((e2) => {
                updateAlertData({ success: false, message: e2.msg || 'Unknown Error Occurred!' });
              });
          },
        },
        `paypal-button-${optedPlan.title}`
      );
    }
  }, [modalOpened]);

  return (
    <>
      <Header hideStats={true} />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Card className='bg-secondary shadow'>
          <CardHeader className='bg-white border-0'>
            <Row className='align-items-center'>
              <Col xs='8'>
                <h3 className='mb-0'>{t('Choose Plans')}</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <br />
              <div className='pl-lg-4'>
                <Row>
                  {plans.map((plan, i) => (
                    <Col lg={12 / plans.length} key={i}>
                      <Card className='plan-cards' {...(user.userSubscription === plan.title ? { outline: true, color: 'success' } : {})}>
                        <CardImg alt={plan.title} src={plan.image} style={{ height: '200px', objectFit: 'contain' }} />
                        <CardBody>
                          <CardTitle>
                            <h1>{t(plan.title)}</h1>
                            <h3>{plan.charges} €</h3>
                            <h5>{t('Per Month')}</h5>
                          </CardTitle>
                          <CardText>
                            <ul>
                              {plan.features.map((ft, index) => (
                                <li key={index}>{t(ft)}</li>
                              ))}
                            </ul>
                          </CardText>
                          <Button
                            color='primary'
                            disabled={user.userSubscription === plan.title}
                            onClick={() => {
                              setOptedPlan(plan);
                              setModalOpen(true);
                            }}
                          >
                            {t('Select')}
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
              <br />
            </Form>
          </CardBody>
        </Card>
      </Container>

      {/* buy plan */}
      <Modal
        className='modal-dialog-centered'
        isOpen={modalOpen}
        toggle={() => {
          setModalOpen(false);
          setModalOpened(false);
        }}
        backdrop='static'
        onOpened={() => setModalOpened(true)}
      >
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            {t('Update Subscription')}
          </h5>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={() => {
              setModalOpen(false);
              setModalOpened(false);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        {/* <div className='modal-body'> */}
        <div className=''>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-5'>
              <div className='text-center'>
                <i className='far fa-credit-card' style={{ fontSize: '50px' }} />

                <h1 className='my-3'>{t('Your account will be updated')}</h1>
                <ul className='text-left'>
                  <li className='py-1'>{t('Changed apply immediately')}</li>
                  <li className='py-1'>{t('Your Invoice will be updated in the next cycle')}</li>
                </ul>

                <h3 className='text-capitalize mb-4'>
                  {t(user.userSubscription)}
                  <i className='fas fa-arrow-right px-2'></i>
                  {t(optedPlan?.title)}
                </h3>
                {optedPlan && <div id={`paypal-button-${optedPlan.title}`}></div>}

                <Button
                  className='my-4'
                  color='default'
                  type='submit'
                  onClick={() => {
                    setModalOpen(false);
                    setModalOpened(false);
                  }}
                >
                  {t('No Thank You')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default PlansPage;
